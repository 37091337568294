//import Vue from 'vue';
import * as func from './functions/_function';
import * as animation from './functions/_animation';
import * as blinderBox from './functions/_bliderBox';
import * as layout from './functions/_layout';
import * as offcanvas from './functions/_offcanvas';
import * as pagetop from './functions/_pagetop';
import * as smoothScroll from './functions/_smoothScroll';
import Modal from '../../node_modules/bootstrap/js/dist/modal'

let WIN_WIDTH                 = window.innerWidth;
let WIN_HEIGHT                = window.innerHeight;
let CURRENT_SC_OFFSET_X       = window.pageXOffset;
let CURRENT_SC_OFFSET_Y       = window.pageYOffset;
let BLAKEPOINT_HEADER_SP_MODE = 480;


offcanvas.setOffcanvas(BLAKEPOINT_HEADER_SP_MODE,'app');
blinderBox.setBlinderBox("全てを表示する","閉じる");
// smoothScroll.setSmoothScroll();

window.addEventListener('scroll', () => {
  CURRENT_SC_OFFSET_X = window.pageXOffset;
  CURRENT_SC_OFFSET_Y = window.pageYOffset;
  
  //引数：固定,固定,ボタンを出現させるきっかけとなる要素のID名,ボタンを固定させるきっかけとなる要素のID名
  // pagetop.setScrolltopBtn(WIN_HEIGHT,CURRENT_SC_OFFSET_Y,'mv','footer');
  
})

window.addEventListener('resize', () => {
  WIN_WIDTH = window.innerWidth;
  WIN_HEIGHT = window.innerHeight;
})

// ------------------------------------------------------------
// Instagram sliser
// ------------------------------------------------------------
//
// https://ideaideal.jp/instafeed-js-and-slick-slider/
//

// $(function (){
//   if($('.js-instagram-slider').length){
//     var getInstagramData = function getInstagramData(callback) {
//       $.getJSON("/wp/wp-content/themes/piknica/instagram.php", function (instagram_data) {
//         console.log(instagram_data);
//         var gallery_data = instagram_data["media"]["data"];
//         var imgs = "";
//         var photo_length = 10;
//
//         for (var i = 0; i < photo_length; i++) {
//           if (gallery_data[i].thumbnail_url) {
//             imgs += '<li class="slider-item"><a class="slider-item__inner" href="' + gallery_data[i].permalink + ' " target="_blank"><img src="' + gallery_data[i].thumbnail_url + '"></a></li>';
//           } else {
//             imgs += '<li class="slider-item"><a class="slider-item__inner" href="' + gallery_data[i].permalink + ' " target="_blank"><img src="' + gallery_data[i].media_url + '"></a></li>';
//           }
//         }
//
//         $(".js-instagram-slider").append(imgs);
//         callback();
//       });
//     };
//
//     var initSlider = function initSlider() {
//       $('.js-instagram-slider').slick({
//         autoplay: true,
//         autoplaySpeed: 3000,
//         dots: true,
//         slidesToShow: 5,
//         centerMode: true,
//         responsive: [{
//           breakpoint: 480,
//           settings: {
//             slidesToShow: 1
//           }
//         }]
//       });
//     };
//
//     new Promise(function (resolve) {
//       getInstagramData(initSlider);
//       resolve();
//     });
//   }
// })

// ------------------------------------------------------------
// 移動動物園
// ------------------------------------------------------------
$(function (){
  console.log('read')
});

$(function (){
  if($('.js_slider01').length){
    $('.js_slider01').slick({
      autoplay: true,
      autoplaySpeed: 3000,
      dots: true,
      slidesToShow: 3,
      centerMode: true,
      responsive: [{
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }]
    });
  }
});
